import React, { useEffect, useContext, useState } from "react";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { isNil } from "lodash";
import * as Sentry from "@sentry/react";
import { compose, mergeSettings } from "../../helpFunctions/general";
import { DataStoreContext } from "./DataStoreContext";
import { TeamBar, TeamBarContainer, ToastMessageContainer, ToastMessage } from "./style";
import { FrButton } from "../DesignSystem/style";
import { ErrorCircle } from "@styled-icons/boxicons-regular/ErrorCircle";
import CustomSelect from "../../components/CustomSelect";
import { OrganizationOption } from "../../components/CustomSelect/customSelectOptions";
import {
  scenariosWithMetadata,
  scenarioIdsWithAccess,
  setOfAllChildScenarios,
  fetchAllChildScenarios,
} from "../../helpFunctions/scenario";
import { checkManager, checkSuperAdmin, checkSharedWith, getIdsWithRoles, getUserType } from "../../helpFunctions/auth";
import { PrivacyPolicy } from "../UserGuide";
import { settings } from "../../constants/defaults";

const mapToListWithId = obj => {
  return Object.keys(obj || {})
    .filter(key => obj[key] !== null)
    .map(key => ({
      id: key,
      ...obj[key],
    }));
};

const handleError = err => {
  Sentry.captureException(err);
  console.error(err);
};

/**
 * The DataStore loads data from Firebase and provides it to the application through the DataStoreContext, typically represented as the props `state` (React Context).
 * The component also contains a few global elements that we might want to render on the page depending on status of the logged in user.
 * It's important to be careful to not load unnecessary data as this can cause high data use. This component will load on every reload of the page.
 *
 * We use selectedUser and selecteOrg to determine what data to load. This is so that an admin can impersonate or choose between multiple user or orgs.
 * For security, the actual permission check also has to happen on Firebase side.
 *
 * We load the following data:
 * - Current organization data if user has organization
 * - All organizations if user is super admin
 * - All scenarios available from selectedOrg and the user's claims
 * - Load all users in org if admin (to display in admin views)
 * - Last 3 personal sessions if regular user, all sessions (but filtered on client) if admin
 *
 */
const DataStore = props => {
  const { dispatch, state } = useContext(DataStoreContext);
  const [emailSent, setEmailSent] = useState(false);

  const loadScenarios = async (authUser, organization) => {
    let scenarioMap = {};
    if (checkSuperAdmin(authUser?.claims)) {
      scenarioMap = (await props.firebase.getScenarios().once("value")).val();
    } else {
      let scenarioIds = scenarioIdsWithAccess(organization, authUser);
      if (scenarioIds.length > 0) {
        // Tries to download the minimum amount of scenarios based on first and last id
        // this will save a lot of data in most cases, but will also include scenarios
        // "in between" that we may not have access to.
        scenarioMap = (
          await props.firebase
            .getScenarios()
            .orderByKey()
            .startAt(scenarioIds[0])
            .endAt(scenarioIds[scenarioIds.length - 1])
            .once("value")
        ).val();
      }
    }

    // Fetch all child scenarios that are not already loaded
    const childScenarios = setOfAllChildScenarios(Object.values(scenarioMap) || []);
    await fetchAllChildScenarios(childScenarios, scenarioMap, props.firebase);

    // TODO we maybe already checked access in scenarioIdsWithAccess, so there could be a more optimized way to do this
    const scenarios = scenariosWithMetadata(scenarioMap, organization?.settings, authUser);
    dispatch({
      type: "SET_SCENARIOS",
      scenarios,
    });
    console.log(`Loaded all ${scenarios?.length} scenarios available to user (for org ${organization?.id})`);
  };

  const loadSessionsFromSpecifiedStartingDate = async () => {
    const isManager = checkManager(props.authUser?.claims, state.selectedOrg);
    props.firebase
      .sessions()
      .orderByChild("created")
      .startAt(state.sessionsFromDate)
      .on("value", snapshot => {
        // NOTE this runs everytime any session changes e.g. when people play
        const sessionObject = snapshot.val() || {};
        const sessionsList = Object.keys(sessionObject)
          .map(key => {
            return {
              ...sessionObject[key],
              auth:
                // check if user has shared this session with us, but managers also get to see the session headers
                // (just for listing purposes)
                checkSharedWith(props.authUser?.claims, sessionObject[key]?.sharedWith, [props.authUser.userId]) ??
                (isManager && sessionObject.organizationId && sessionObject.organizationId === state.selectedOrg),
              id: key,
            };
          })
          .filter(s => s?.auth !== null && s?.sessionLength && s?.sessionLength > 0);
        if (!state.sessions) console.log(`Loaded ${sessionsList.length} sessions from date ${state.sessionsFromDate}`);
        else console.log(`Updated changes from sessions`);

        dispatch({ type: "SET_SESSIONS", sessions: sessionsList });
      });
  };

  const loadPersonalSessions = async userId => {
    return props.firebase
      .sessions()
      .orderByChild("playerId")
      .equalTo(userId)
      .limitToLast(3)
      .on("value", snapshot => {
        // Will re-run when new sessions created that match query. TODO ensure only on new sessions?
        const sessionObject = snapshot.val() || {};
        const sessionsList = Object.keys(sessionObject)
          .map(key => {
            // null if not the player, false if player but it's an assessment, otherwise true
            return {
              ...sessionObject[key],
              auth: checkSharedWith(props.authUser?.claims, sessionObject[key]?.sharedWith, [props.authUser.userId]),
              id: key,
            };
          })
          .filter(s => s?.auth !== null && s?.sessionLength && s?.sessionLength > 0);
        console.log(`Loaded last ${sessionsList.length} personal sessions`);
        dispatch({ type: "SET_PERSONAL_SESSIONS", personalSessions: sessionsList });
      });
  };

  const loadOrganizations = async (authUser, selectedOrg) => {
    let organizations = [];
    let selectedOrgObject = {};
    const isSuperAdmin = checkSuperAdmin(authUser?.claims);
    let claimedOrgs = getIdsWithRoles(authUser?.claims, null);
    if (!isSuperAdmin && claimedOrgs.indexOf(selectedOrg) === -1 && claimedOrgs.length > 0) {
      console.error(
        `User does not have access to selected org ${selectedOrg}, changing to first available: ${claimedOrgs[0]}`
      );
      dispatch({ type: "SET_SELECTED_ORG", selectedOrg: claimedOrgs[0] });
      return;
    }
    if (isSuperAdmin) {
      // Super admin loads all orgs
      organizations = mapToListWithId((await props.firebase.getOrganizations().once("value")).val());
    } else if (claimedOrgs.length) {
      let orgs = await props.firebase.getSpecificOrganizations(claimedOrgs);
      organizations = mapToListWithId(orgs);
    } else {
      console.warn("User does not have access to any organizations");
    }
    selectedOrgObject = organizations.find(org => org.id === selectedOrg) || {};

    console.log(`Loaded all ${organizations.length} organizations available to user`);
    dispatch({ type: "SET_ORGANIZATIONS", organizations });
    dispatch({ type: "SET_ORGANIZATION", organization: selectedOrgObject });
  };

  const loadMergedSettings = (authUser, organization) => {
    // Merge in user and org settings to default settings
    const merged = mergeSettings([settings, authUser?.settings, organization?.settings]);
    console.log(`Loaded ${Object.keys(merged || {}).length} merged settings`);
    dispatch({ type: "SET_SETTINGS", settings: merged });
  };

  const loadOrganizationUsers = async organizationId => {
    if (!organizationId) return;
    const userMap = (
      await props.firebase.getUsers().orderByChild("organization").equalTo(organizationId).once("value")
    ).val();

    const users = mapToListWithId(userMap);
    console.log("Loaded all users in org as user is admin");
    dispatch({ type: "SET_USERS", users: users });
  };

  // Note on loading order and exhaustive deps:
  // We use useEffects to create a dependency tree for loading. Whenever a dependency changes, all
  // dependent branches and sub-branches in the tree will be loaded. This is carefully setup to work so
  // don't change without detailed analysis.
  // Note that we set to ignore exhaustive-deps because we don't want to trigger useEffects on all technically
  // dependent changes, just the ones we decide.

  // Set selected user and org from authUser
  useEffect(() => {
    if (props.authUser.userId) {
      dispatch({ type: "SET_SELECTED_USER", selectedUser: props.authUser.userId });
      // setPreviousSelectedUser(state.selectedUser)
    }
    if (props.authUser.organization) {
      dispatch({ type: "SET_SELECTED_ORG", selectedOrg: props.authUser.organization });
      // setPreviousSelectedOrg(state.selectedOrg)
    }
    console.log(
      `Set selected user (${props.authUser?.userId}) and org (${props.authUser?.organization}) from authUser on initial load`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authUser]);

  // Load all organizations available to user (based on claims)
  useEffect(() => {
    if (state.selectedUser) {
      loadOrganizations(props.authUser, state.selectedOrg).catch(handleError); // TODO, should be based on selected user
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedUser, state.selectedOrg]);

  // Load all scenarios and organization users available (based on org, and claims)
  useEffect(() => {
    // Load scenarios only if state.organization has been resolved (not null, but can be empty obj)
    if (props.authUser && !isNil(state.organization)) {
      loadScenarios(props.authUser, state.organization).catch(handleError);
      loadMergedSettings(props.authUser, state.organization);
    }

    if (checkManager(props.authUser?.claims, state.selectedOrg)) {
      loadOrganizationUsers(state.selectedOrg).catch(handleError);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.organization, props.authUser]);

  // Load personal sessions
  useEffect(() => {
    if (state.selectedUser) {
      loadPersonalSessions(state.selectedUser).catch(handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedUser]);

  // Load sessions whenever on selected from date changes
  useEffect(() => {
    if (state.sessionsFromDate !== null) {
      loadSessionsFromSpecifiedStartingDate().catch(handleError);
    } else {
      // Stop listening
      props.firebase.sessions().off();
      console.log("Stopped listening to new sessions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sessionsFromDate]);

  const sendVerificationEmail = () => {
    if (!emailSent) {
      props.firebase.doSendEmailVerification();
      setEmailSent(true);
    }
  };

  const onOrgChange = e => {
    dispatch({
      type: "SET_SELECTED_ORG",
      selectedOrg: e.id,
    });
  };

  /**
   * Changes the current organization for a user, used by admins.
   */
  const changeOrganization = () => {
    // TODO check that we can select this org
    props.firebase.getUser(props.authUser.userId).update({
      organization: state.selectedOrg,
    });
  };

  const selectedTeamName =
    state.organizations?.find(org => org.id === state.selectedOrg)?.displayName || state.selectedOrg;
  const roleName = getUserType(props.authUser.claims, state.selectedOrg);
  const unverifiedWarning =
    props.firebase?.auth?.currentUser?.email && !props.firebase?.auth?.currentUser?.emailVerified;
  const noOrgWarning = props.authUser && (!props.authUser.organization || props.authUser.organization === "");

  return (
    <>
      {state.selectedOrg && state.organizations?.length > 1 && (
        <TeamBar>
          <TeamBarContainer>
            <label>Current organization</label>
            <CustomSelect
              isSearchable={true}
              isDisabled={false}
              isMulti={false}
              getOptionLabel={option => `${option.id} ${option.displayName}`}
              onChange={e => onOrgChange(e)}
              options={state.organizations}
              value={state.selectedOrg}
              placeholder={`${selectedTeamName} (${roleName})`}
              option={OrganizationOption}
            />
            {props.authUser.organization && (
              <FrButton
                onClick={() => changeOrganization()}
                margin={"0 0 0 10px"}
                disabled={props.authUser.organization === state.selectedOrg}
              >
                Save choice
              </FrButton>
            )}
          </TeamBarContainer>
        </TeamBar>
      )}

      {(unverifiedWarning || noOrgWarning) && (
        <ToastMessageContainer>
          {unverifiedWarning && (
            <ToastMessage>
              {!emailSent && (
                <div>
                  <ErrorCircle />
                  <h5>
                    Your email is not verified so your account is limited, check your mailbox for verification email.
                  </h5>
                </div>
              )}
              <div>
                <h5>I have verified my email adress</h5>
                <button onClick={() => window.location.reload()}>Done</button>
                <h5>Can't find it?</h5>
                <button disabled={emailSent} onClick={() => sendVerificationEmail()}>
                  {emailSent ? "Email sent" : "Resend email"}
                </button>
              </div>
            </ToastMessage>
          )}
          {/* TODO this may need to look at claims instead of current organization */}

          {noOrgWarning && (
            <ToastMessage>
              <div>
                <ErrorCircle />
                <h5>
                  You don't belong to an organization and has limited access to this app. Contact your admin or support
                  if this is unexpected.
                </h5>
              </div>
            </ToastMessage>
          )}
        </ToastMessageContainer>
      )}

      {/* {!state.loading && props.authUser && props.authUser.noDbUser && (
        <AddUserToDB doRegisterUser={props.firebase.doRegisterUser} authUser={props.authUser} />
      )} */}

      {!state.loading && props.authUser && !props.authUser.termsConsent && (
        <PrivacyPolicy
          authUser={props.authUser}
          firebase={props.firebase}
          history={props.history}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition, true), withFirebase)(DataStore);
